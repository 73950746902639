@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer components {
  p {
      @apply md:text-lg mb-4 font-light text-secondary;
  }

  .highlight {
      @apply text-accent font-bold;
  }
}